html {
    font-size: 16px; /* Default. In all elements the fonts are associated with this value via rem. Change this if you want to change the site font size */
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #5f5f5f;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    box-sizing: border-box;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    sans-serif !important;
    margin: 0;
}

.MuiPopover-paper {
    border-radius: 8px !important;
    border: 1px solid #E0E1E3;
    box-shadow: 0px 1px 10px 0px rgba(186, 144, 255, 0.20) !important;
}

.MuiMenuItem-root {
border-top: 1px solid #E0E1E3 !important;
    justify-content: center !important;
}

.MuiMenuItem-root:first-child {
    border-top-style: none !important;
}

.MuiList-root{
    padding: 0 !important;
}

.speedometer-tooltip::before {
    width:0px !important;
    height:0px !important;
}
